body {
  font: 14px "Century Gothic", Futura, sans-serif;
}

.session {
  margin: 24px 0;
}

.header {
  background-color: #f0f2f5;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
  border: '1px solid rgb(235, 237, 240)';
}

.content {
  padding: 10px;
  margin: 12px 24px;
  background: #f0f2f5;
}

.logs .innerLogs {
    padding: 16px;
    background-color: #fafafa;
    margin-bottom: 0;
}

.steps, .titleRow {
  display: block;
}

.steps {
  border-top: 1px solid;
}

.step, .stepTitle, .titleRow, .titleCell1, .titleCell2 {
  display: inline-block;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;

}

.titleCell1, .titleCell2 {
  padding-left: 5px;
}

.stepTitle, .titleCell1 {
  width: 200px;
}

.titleCell2 {
  width: 110px;
}

.step {
  width: 110px;
  text-align: center;
}

.step div {
  display: block;
}

div.testLogsStyle div.ant-collapse-content {
  background-color: #333333;
  color: #33ff33;
  font-family: Monospace;
}

.init .stepState {
  background-color: #c2c2d6;
  color: #c2c2d6;
}

.in_progress .stepState {
  background-color: #ffcc80;
}

.complete .stepState {
  background-color: #79d279;
}

.failed .stepState {
  background-color: #ff6666;
}